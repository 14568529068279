import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import SearchEngineOptimization from "../components/utility/SearchEngineOptimization";
import Articles from "../components/blog/ArticlesSection";
import AboutCard from "../components/cards/AboutCardPortrait";
import SupportBand from "../components/general/SupportBand";
import TipJar from "../components/utility/TipJar";

const CategoryStyles = styled.div`
  .category-page {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    padding-left: 5%;
    padding-right: 5%;

    @media (max-width: 950px) {
      margin-top: 80px;
    }

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      margin-top: 20px;
    }
  }

  .sidebar {
    .sidebar-about {
      margin-bottom: 1rem;
    }
  }

  .articles {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 2rem;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }

  .bottom-about {
    display: none;
  }

  h1 {
    text-align: center;
  }

  .back {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .forward {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .pagination-button,
  .pagination-button-back {
    padding: 1rem;
    background-color: var(--black);
    color: var(--white);
    margin-left: 5%;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 900px) {
    display: block;

    .category-description {
      padding: 1rem;
    }

    .sidebar {
      display: none;
    }

    .bottom-about {
      display: block;
      margin-bottom: 1rem;
    }
  }

  .sidebar-tip-jar img {
    width: 600px;
    max-width: 100%;
  }
`;

export function Head({ data: { category }, location, ...props }) {
  return (
    <SearchEngineOptimization
      {...props}
      meta={{
        title: category.title.toUpperCase(),
        description: category.description,
        image: category.image.image.asset.gatsbyImageData,
        alt: category.image.alt,
        ogType: "article",
        createdAt: category._createdAt,
        updatedAt: category._updatedAt,
      }}
      location={location}
    />
  );
}

export default function categoryPage({
  data: {
    blogs: { nodes: blogs },
    category,
    about,
    paymentGateways,
  },
  pageContext,
  location,
}) {
  return (
    <>
      <CategoryStyles>
        <div className="category-page">
          <div className="content">
            <h1>{category.title}</h1>
            <p className="category-description">
              <strong>{category.description}</strong>
            </p>

            <Articles blogs={blogs} isInfinite={true} />
          </div>

          <div className="sidebar">
            <div className="sidebar-about">
              <AboutCard about={about} signup={category.signup} />
            </div>

            <div className="sidebar-tip-jar">
              <TipJar />
            </div>
          </div>
        </div>
        <div className="bottom-about">
          <AboutCard about={about} signup={category.signup} />
        </div>
        <div className="bottom-support-band">
          <SupportBand support={paymentGateways} />
        </div>
      </CategoryStyles>
    </>
  );
}

export const query = graphql`
  query ($slug: String) {
    category: sanityCategory(slug: { current: { eq: $slug } }) {
      _createdAt
      _updatedAt
      description
      slug {
        current
      }
      signup
      title
      image {
        image {
          asset {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    }

    blogs: allSanityBlog(
      filter: {
        publicityStatus: { eq: "PUBLISHED" }
        blogCategory: { slug: { current: { eq: $slug } } }
      }
    ) {
      nodes {
        title
        description
        blogCategory {
          title
          slug {
            current
          }
        }
        tags {
          slug {
            current
          }
          title
        }
        slug {
          current
        }

        image {
          image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }

        textEditor {
          _key
          _type
          _rawRichText
        }
      }
    }

    about: sanitySection(title: { eq: "About Family I Love" }) {
      title
      textEditor {
        _key
        _type
        _rawRichText(resolveReferences: { maxDepth: 10 })
      }
    }

    paymentGateways: sanitySection(title: { eq: "Support The Band" }) {
      textEditor {
        _rawRichText(resolveReferences: { maxDepth: 10 })
      }
      title
    }
  }
`;
